/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ILoginPageLayoutProps } from "./types";
import classNames from "classnames";
import { LogoSuccessIcon } from "../../../svg-icons/icon-logo-success"

import styles from "./login-page-layout.module.scss";

function LoginPageLayout({
  children,
  pageTitle
}: ILoginPageLayoutProps) {

  return (

    <div
      className={classNames({
        [`${styles.main_wrapper}`]: true,
      })}
    >
      <div className={styles.app_content}>
        <div className={styles.logo_container}>
          <LogoSuccessIcon />
        </div>

        <div className={styles.page_title}>
          {pageTitle}
        </div>
        <div className={styles.page_content}>
          {children}
        </div>

      </div>
    </div>
  );
}

export default LoginPageLayout;
