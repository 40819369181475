import React, { useState, useEffect, useCallback } from "react";
import { ITerminal } from "../../interfaces";
import classNames from "classnames"
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import {
  NAV,
  amountFormatter,
  capitalizeFirstLetter,
  selleInfoDefault,
  containsAny,
  countCharactersBeforeDot,
  countCharactersAfterDot,
  QR,
  miaPosEvents,
  mockActiveQrInfo
} from "../../constants"
import MiaPos from "../../_bridge/init_miapos"
import { downloadPdfFile } from "../../hooks"
import { ErrorFillIcon } from "../../svg-icons/icon-error-fill"
import { useSelector, useDispatch } from "react-redux";
import { getDayStatus } from "../../api/selectors/mainSelector"
import { DayIsClosedIcon } from "../../svg-icons/icon-day-is-closed"
import { setDayStatus, setShowModalOpenCloseDay } from "../../api/actionCreators/mainActionCreator"
import CircularProgress from '@mui/material/CircularProgress';
import { LogoIcon } from "../../svg-icons/icon-logo"
import { LogoTextIcon } from "../../svg-icons/icon-logo-text"
import { BackspaceIcon } from "../../svg-icons/icon-backspace"
import { GenerateQrIcon } from "../../svg-icons/icon-generate-qr"
import { CloseRemoveIcon } from "../../svg-icons/icon-close-remove"
import { QrCodeIcon } from "../../svg-icons/icon-qr-code"
import { LogoSuccessIcon } from "../../svg-icons/icon-logo-success"
import { SuccessIcon } from "../../svg-icons/icon-success"
import { ErrorIcon } from "../../svg-icons/icon-error"
import { CopyIcon } from "../../svg-icons/icon-copy"
import axios from "../../api/services/Api"
import moment from "moment"
import { NextIcon } from "../../svg-icons/icon-next"
import { BackButtonIcon } from "../../svg-icons/icon-back-button"
import { ShareIcon } from "../../svg-icons/icon-share"
import { DownloadIcon } from "../../svg-icons/icon-download"
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import {
  removeToken,
  removeRefreshToken,
  removeDayState,
  removeExpiredTime,
  removeTerminalId,
  storeShowLoginForm,
  removeSellerInfo,
  getSellerInfo,
  storeDayState,
  storeShiftId,
  removeShiftId,
  storeSaveCreateQrDate,
  removeRequestPayment,
  saveRequestPayment
} from "../../api/services/Auth";
import {
  setIsAuthFetching
} from "../../api/actionCreators/authActionCreator";
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import styles from "./terminal.module.scss";



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Terminal = (props: ITerminal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const qrMock = {
  //   qrType: QR.STATIC,
  //   amountType: QR.CONTROLLED,
  //   extension: {
  //     amount: "",
  //     minAmount: "",
  //     maxAmount: ""
  //   }
  // }
  // useEffect(() => {

  //   const requestParam = { // for test
  //     requestId: "testtesttesttesttesttesttesttesttest", amount: 951, currency: "mdl"
  //   }
  //   saveRequestPayment(JSON.stringify(requestParam))

  // }, [])



  const [amount, setAmount] = useState("")
  const [date, setDate] = useState(moment().format("HH:mm DD.MM.YYYY"))
  const [ccy, setCcy] = useState("MDL")
  const [loaderText, setLoaderText] = useState("")
  const [errorDescription, setErrorDescription] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [showScanToPay, setShowScanToPay] = useState(false)
  const [showSuccessView, setShowSuccessView] = useState(false)
  const [isEditAmountModal, setIsEditAmountModal] = useState(false)
  const [showDeactivatedModal, setShowDeactivatedModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showDeactivatedModalForHybrid, setShowDeactivatedModalForHybrid] = useState(false)
  const [showQrCodeGenerationError, setShowQrCodeGenerationError] = useState(false)
  const [showError, setShowError] = useState(true)
  const [retryAction, setRetryAction] = useState(false)
  const [isFetchingQrInfo, setIsFetchingQrInfo] = useState(true)
  const [amountError, setAmountError] = useState(false)
  const [updatingAmountAndQrInfo, setUpdatingAmountAndQrInfo] = useState(false)
  const [checkRequestPayment, setCheckRequestPayment] = useState(false)
  const [requestPaymentAlredySet, setRequestPaymentAlredySet] = useState(true)

  const [isDisabledEditAmountSaveButton, setIsDisabledEditAmountSaveButton] = useState(false)
  const [runFetchCheckActiveQr, setRunFetchCheckActiveQr] = useState(true)
  const [isDeactivating, setIsDeactivating] = useState(false)
  const [progress, setProgress] = useState(10);
  const [checkQrId, setCheckQrId] = useState(5);

  const [txId, setTxId] = useState(null);
  const [selectedQr, setSelectedQr] = useState("");
  const [deactivateAction, setDeactivateAction] = useState("");
  const [selectedQrAmount, setSelectedQrAmount] = useState("");
  const [activeQrCodeInfo, setActiveQrCodeInfo] = useState({ ...mockActiveQrInfo })
  // const [qrInfo, setQrInfo] = useState({ ...qrMock })
  const [activeAmountField, setActiveAmountField] = useState("")
  const [modalValues, setModalValues] = useState({ amount: null, minAmount: null, maxAmount: null })
  const [modalValuesOld, setModalValuesOld] = useState({ amount: null, minAmount: null, maxAmount: null })

  const [sellerInfo, setSellerInfo] = useState(selleInfoDefault)

  const isDayOpen = useSelector(getDayStatus);
  const dayState = localStorage.getItem('_day_state');
  const terminalIdStore = localStorage.getItem('_terminal_id');
  const token = localStorage.getItem('_session_token');
  const qrStoreDate = localStorage.getItem('_qr_date');
  const qrSettings = JSON.parse(localStorage.getItem("_qr_settings"));
  const requestPayment = localStorage.getItem('_request_payment');
  const rPayment = JSON.parse(requestPayment);
  const qrType = qrSettings?.currentType;
  const amountType = qrSettings?.currentAmountType;
  const { SEND_EXTERNAL_PAYMENT_RESULT, SAVE_FILE, START_NFC, STOP_NFC } = miaPosEvents

  useEffect(() => {
    setModalValues({
      ...mockActiveQrInfo.extension,
      ...activeQrCodeInfo.extension
    })
    setModalValuesOld({
      ...mockActiveQrInfo.extension,
      ...activeQrCodeInfo.extension
    })
  }, [isEditAmountModal])

  // static - controlled / fixed / free
  // hybrid - fixed / controlled
  // dynamic - fixed

  useEffect(() => {
    const platformType = localStorage.getItem("x_platform_type");
    if (platformType === "android") {
      if (showScanToPay && !!activeQrCodeInfo?.extension?.extensionId?.length) {

        const params = {
          paymentLink: activeQrCodeInfo.qrText,
          lifetimeSec: activeQrCodeInfo?.extension?.qrExpireIn || null
        }
        MiaPos.send(START_NFC, { ...params }).then(res => {
          if (res.result && res.result === "success") {
            enqueueSnackbar(t("Nfc_started"), { variant: "success" })
          } else if (res.result && res.result === "nfc_disabled") {
            enqueueSnackbar(t("Nfc_disabled"), { variant: "error" })
          }
        })
      } else if (!showScanToPay) {
        MiaPos.send(STOP_NFC)
      }
    }

  }, [showScanToPay, activeQrCodeInfo])

  useEffect(() => {
    if (checkRequestPayment) {
      rPayment && activeQrCodeInfo?.qrId && activeQrCodeInfo?.qrId !== "" && qrType === QR.HYBRID && setShowScanToPay(true)

      if (!activeQrCodeInfo.extension?.amount) {
        if (isDayOpen && qrType !== QR.STATIC && amountType !== QR.CONTROLLED) {
          setModalValues({
            ...mockActiveQrInfo.extension,
            ...activeQrCodeInfo.extension,
            amount: rPayment?.amount || 0,
          })
          if (qrType === QR.DYNAMIC && rPayment) {


            setActiveQrCodeInfo({
              ...activeQrCodeInfo,
              extension: {
                ...activeQrCodeInfo.extension,
                amount: rPayment?.amount,
              }
            })

            setRetryAction(true)
            // setCheckQrId(0)
          } else if (rPayment?.amount && qrType === QR.HYBRID && rPayment?.amount && (!activeQrCodeInfo?.qrId || activeQrCodeInfo?.qrId === "")) {
            setActiveQrCodeInfo({
              ...activeQrCodeInfo,
              extension: {
                ...activeQrCodeInfo.extension,
                amount: rPayment.amount,
                // extensionId: rPayment.requestId
              }
            })

            enqueueSnackbar(t("Required_generate_qr"), { variant: "warning" })
          } else if (rPayment?.amount && qrType === QR.HYBRID && (activeQrCodeInfo?.qrId || activeQrCodeInfo?.qrId.length > 0)) {
            setModalValues({
              ...mockActiveQrInfo.extension,
              ...activeQrCodeInfo.extension,
              amount: rPayment?.amount,
            })
            onIsEditAmountModal(activeQrCodeInfo)
            setShowScanToPay(true)

          }

        } else if (rPayment?.amount && (qrType === QR.STATIC || amountType === QR.CONTROLLED)) {
          enqueueSnackbar(t("Error_generate_qr"), { variant: "error" })
        }
      }


    }
    //setRequestPaymentAlredySet(true)
    //console.log("rPayment", rPayment, qrType, requestPaymentAlredySet)
  }, [checkRequestPayment, requestPaymentAlredySet])

  useEffect(() => {


    if (qrSettings) {
      setSelectedQr(qrType)
      setSelectedQrAmount(amountType)
      amountType === QR.CONTROLLED ? setActiveAmountField(QR.MIN_AMOUNT) : setActiveAmountField("")
    }

  }, [])

  useEffect(() => {
    dispatch(setIsAuthFetching(false))
    terminalIdStore && token ? axios
      .get(`/pos/api/v1/shift/check`)
      .then(res => {
        const { data } = res
        if (data && data.state) {
          if (data.state === "closed") {
            setIsFetchingQrInfo(false)
          }
          dispatch(setDayStatus(data.state === "opened"))
          data?.shiftId && storeShiftId(data.shiftId)
          storeDayState(data.state)
          setRunFetchCheckActiveQr(true)
          data.state === "opened" && setIsFetchingQrInfo(true)
        }
      }) : removeLocalStorAndRefresh("seller_is_inactive", true)


  }, [dispatch, terminalIdStore, token])


  useEffect(() => {

    if (runFetchCheckActiveQr) {
      if (isDayOpen && !!selectedQr) {
        setIsFetchingQrInfo(true)
        setUpdatingAmountAndQrInfo(true)
        axios
          .get(`/pos/api/v2/qr/active?type=${selectedQr}&isImageRequired=true`)
          .then(res => {

            setIsFetchingQrInfo(false)
            setRunFetchCheckActiveQr(false)
            setTimeout(() => {
              setUpdatingAmountAndQrInfo(false)
            }, 1000);

            const { data: { codes = [], result = "" } } = res

            if (result === "success") {
              setActiveQrCodeInfo({ ...mockActiveQrInfo })

              codes.length > 0 && setActiveQrCodeInfo(codes[0])
              setCheckRequestPayment(true)
            } else if (result === "seller_is_inactive") {
              removeLocalStorAndRefresh("seller_is_inactive", true)
            } else if (result === "terminal_is_inactive") {
              removeTerminalId()
              removeLocalStorAndRefresh("terminal_is_inactive", false, false)
            }
            setShowLoader(false)
          }).finally(() => setIsFetchingQrInfo(false))
      }

    }


  }, [selectedQr, runFetchCheckActiveQr, isDayOpen])

  useEffect(() => {
    setSellerInfo(JSON.parse(getSellerInfo()))
  }, [])

  const resetQrScanning = useCallback((resetAmount = false, inSelectedQr, showError = false) => {

    inSelectedQr === QR.HYBRID && !showError ? setShowScanToPay(true) : setShowScanToPay(false)
    resetModalValues()
    setRunFetchCheckActiveQr(true)
    setShowSuccessView(false)
    setShowLoader(false)
    setProgress(10)
    setShowQrCodeGenerationError(false)
    resetAmount && setAmount("")
  }, [])

  useEffect(() => {
    if (showSuccessView) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => prevProgress - 1);
      }, 1000);
      if (progress === 0) {
        resetQrScanning(true, selectedQr)
        clearInterval(timer);
      }
      return () => {
        clearInterval(timer);
      };
    }
  }, [progress, showSuccessView, resetQrScanning]);

  const removeLocalStorAndRefresh = useCallback((err, redirectToLoginForm = false, showSnackbar = true) => {
    redirectToLoginForm && storeShowLoginForm()
    showSnackbar && enqueueSnackbar(t(capitalizeFirstLetter(err)))
    setTimeout(() => {
      removeToken()
      removeRefreshToken()
      removeDayState()
      removeSellerInfo()
      removeExpiredTime()
      removeShiftId()
      window.location.reload()
    }, 1000);
  }, [t])

  useEffect(() => {

    if (retryAction) {
      setShowLoader(true)
      setRetryAction(false)
      setLoaderText(t("Getting_a_response"))
      const myAmount = activeQrCodeInfo?.extension?.amount[activeQrCodeInfo?.extension?.amount?.length - 1] === "." ? activeQrCodeInfo?.extension?.amount.slice(0, -1) : activeQrCodeInfo?.extension?.amount;
      const myMinAmount = activeQrCodeInfo?.extension?.minAmount[activeQrCodeInfo?.extension?.minAmount.length - 1] === "." ? activeQrCodeInfo?.extension?.minAmount.slice(0, -1) : activeQrCodeInfo?.extension?.minAmount;
      const myMaxAmount = activeQrCodeInfo?.extension?.maxAmount[activeQrCodeInfo?.extension?.maxAmount.length - 1] === "." ? activeQrCodeInfo?.extension?.maxAmount.slice(0, -1) : activeQrCodeInfo?.extension?.maxAmount;

      const extUpdated = {
        amount: rPayment && rPayment?.amount ? rPayment.amount : myAmount === "" ? 0 : Number(myAmount),
        minAmount: myMinAmount === "" ? 0 : Number(myMinAmount),
        maxAmount: myMaxAmount === "" ? 0 : Number(myMaxAmount),
      }
      const params = {
        qrType: selectedQr,
        amountType: selectedQrAmount,
      }
      let objParams = {}

      if (selectedQr !== QR.HYBRID && selectedQrAmount !== QR.FREE) {
        objParams = {
          ...params,
          extension: {
            ...extUpdated
          }
        }
      } else {
        objParams = {
          ...params
        }
      }

      axios
        .post(`/pos/api/v2/qr`, { ...objParams })
        .then(res => {

          const { result } = res.data
          console.log("/pos/api/v2/qr")

          console.log("rPayment.amount && result111", rPayment, result)
          storeSaveCreateQrDate(moment().toString())
          if (result === "success") {
            // rPayment?.amount && removeRequestPayment()
            const qrType = qrSettings.currentType;


            if (rPayment?.amount && (qrType === QR.HYBRID || qrType === QR.DYNAMIC) && amountType !== QR.CONTROLLED) {
              setModalValues({
                ...mockActiveQrInfo.extension,
                amount: rPayment.amount,
              })

              setActiveQrCodeInfo({
                ...res.data.qrInfo,
                extension: {
                  ...res.data.qrInfo.extension,
                  amount: rPayment.amount,
                }
              })
              qrType === QR.HYBRID && onIsEditAmountModal(res.data.qrInfo)
            } else {
              setActiveQrCodeInfo(res.data.qrInfo)
            }

            setShowScanToPay(true)
            setShowLoader(false)
            setLoaderText("")

          } else if (result === "exist_active_qr") {
            setShowLoader(false)
            enqueueSnackbar(t(capitalizeFirstLetter(result)))
          } else if (result === "seller_is_inactive") {
            setShowLoader(false)
            removeLocalStorAndRefresh("seller_is_inactive", true)
          } else if (result === "terminal_is_inactive") {
            setShowLoader(false)
            removeTerminalId()
            removeLocalStorAndRefresh("terminal_is_inactive", false, false)
          } else if (result === "external_error") {
            setShowQrCodeGenerationError(true)
            //enqueueSnackbar(t(capitalizeFirstLetter(result)))
            setShowLoader(false)
          }
          const platformType = localStorage.getItem("x_platform_type");
          if (platformType !== "web" && result !== "success") {
            sendMiaPaymentResult(result, { amount: "", currency: "", date: "", mcc: "" }, "generate_error")
          }


          // rPayment?.amount && removeRequestPayment()
        })
        .catch(() => {
          setShowLoader(false)
        })
    }
  }, [retryAction, amount, removeLocalStorAndRefresh]);

  useEffect(() => {
    // setLoaderText(t("Getting_a_response"))
    if (showScanToPay && activeQrCodeInfo.extension?.extensionId && !showSuccessView) {

      const checkPayment = setInterval(() => {
        setCheckQrId((prevProgress) => prevProgress - 1);
      }, 1000);
      if (checkQrId === 0) {
        axios
          .get(`/pos/api/v2/qr/extension/${activeQrCodeInfo.extension.extensionId}/check`)
          .then(res => {
            const { lastTxInfo, state } = res.data
            console.log("state++++++++++++++++ ", state)
            let myError = ""
            if (lastTxInfo) {
              setAmount(lastTxInfo.amount)
              setCcy(lastTxInfo.ccy)
              setTxId(lastTxInfo.id)
            }
            if (state === "paid") {
              setDate(lastTxInfo.date)
              setShowError(false)
              setShowLoader(true)
              setLoaderText(t("Check_payment"))
              setIsEditAmountModal(false)
              setShowDeactivatedModal(false)
              setShowDeactivatedModalForHybrid(false)
              setShowShareModal(false)


              setTimeout(() => {
                setShowLoader(false)

                setShowSuccessView(true)
                setCheckQrId(5)
              }, 1000);


              return () => {
                clearInterval(checkPayment);
              };

            } else if (state === "pending") {
              setCheckQrId(5)
            } else if (state === "error") {
              myError = "error"
              setIsEditAmountModal(false)
              setShowDeactivatedModal(false)
              setShowDeactivatedModalForHybrid(false)
              setShowShareModal(false)
              setShowLoader(true)
              setErrorDescription(res.data.errorDescription)
              setLoaderText(t("Check_payment"))

              setTimeout(() => {
                setShowLoader(false)
                setShowSuccessView(true)
                setShowError(true)
                setCheckQrId(5)
              }, 1000);

            } else if (state === "cancelled") {
              myError = "cancelled"
              setIsEditAmountModal(false)
              setShowDeactivatedModal(false)
              setShowDeactivatedModalForHybrid(false)
              setShowShareModal(false)
              setShowLoader(true)
              setErrorDescription(t('QR_cancelled'))
              setLoaderText(t("Check_payment"))
              setTimeout(() => {
                setShowLoader(false)

                setShowSuccessView(true)
                setShowError(true)
                setCheckQrId(5)
              }, 1000);

            } else if (state === "expired") {
              myError = "expired"
              setIsEditAmountModal(false)
              setShowDeactivatedModal(false)
              setShowDeactivatedModalForHybrid(false)
              setShowShareModal(false)
              setShowLoader(true)
              setErrorDescription(t('QR_expired'))
              setLoaderText(t("Check_payment"))
              setTimeout(() => {
                setShowLoader(false)
                setShowSuccessView(true)
                setShowError(true)
                setCheckQrId(5)
              }, 1000);

            } else {
              myError = "wait_pay_error"
              enqueueSnackbar(capitalizeFirstLetter(state))
              setShowLoader(false)
              resetQrScanning(true, selectedQr)
              setIsEditAmountModal(false)
              setShowDeactivatedModal(false)
              setShowDeactivatedModalForHybrid(false)
              setShowShareModal(false)
              setLoaderText(t("Check_payment"))
            }


            console.log("rPayment", state)
            const platformType = localStorage.getItem("x_platform_type");
            if (platformType !== "web" && state !== "pending") {
              sendMiaPaymentResult(state, lastTxInfo, myError)
            }


            if (state === "paid") {
              setTimeout(() => {
                setShowLoader(false)

                setShowSuccessView(true)
                setCheckQrId(5)
              }, 1000);


              return () => {
                clearInterval(checkPayment);
              };
            }
          })
      }

      return () => {
        clearInterval(checkPayment);
      };

    }
  }, [showScanToPay, checkQrId, resetQrScanning, t, isEditAmountModal])

  const sendMiaPaymentResult = (state, lastTxInfo, errorReason) => {
    let androidObj = {}
    const status = state === "paid" || state === "success" ? "success" : "failed"
    const params = {
      requestId: rPayment.requestId,
      status,
    }

    if (status === "failed") {
      const errObj = { errorReason }
      androidObj = {
        ...params,
        txInfo: null,
        errorReason
      }
    } else {
      androidObj = {
        ...params,
        txInfo: {
          amount: lastTxInfo?.amount || "",
          currency: lastTxInfo?.ccy || "",
          date: lastTxInfo?.date || "",
          mcc: lastTxInfo?.mсс || "",
        }
      }
    }
    console.log("androidObj", androidObj)
    MiaPos.send(SEND_EXTERNAL_PAYMENT_RESULT, { ...androidObj }).then(res => {
      res.result && removeRequestPayment()
    })
  }

  const renderSuccessView = () => {
    return (
      <div className={styles.succuss_view_container}>
        <div className={styles.succuss_view_content}>
          <div className={styles.succuss_view_content_success_info}>
            <div className={styles.succuss_view_content_logo}>
              <LogoSuccessIcon />
            </div>
            <div className={styles.succuss_view_content_info}>
              {!showError ? <SuccessIcon /> : <ErrorIcon />}
              <div className={styles.success_title}>
                {!showError ? t('Paid_successfully') : t("Payment_error")}
              </div>
              <div className={styles.success_description}>
                <h4>
                  {amountFormatter(amount)} <span className={styles.uppercase}>{ccy}</span>
                </h4>
                {!showError ?
                  <>
                    <div className={styles.success_line}>
                      <div>{t('Date')}:</div>
                      <div>{moment(date).format("HH:mm, DD.MM.YYYY")}</div>
                    </div>
                    <div className={styles.success_line}>
                      <div>{t('Transaction_id')}:</div>
                      <div>{txId}</div>
                    </div>
                  </> :
                  <>
                    <div className={styles.success_line}>
                      <div>{t('Description')}:</div>
                      <div>{errorDescription}</div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div className={styles.btn_container} onClick={() => resetQrScanning(true, selectedQr, showError)}>
            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
              })}
            >
              {"OK"}({progress})
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderQrCodeGenerationError = () => {
    return (
      <div className={styles.qr_code_generation_error_container}>
        <div className={styles.qr_code_generation_error_content}>
          <div className={styles.qr_code_generation_error_content_success_info}>
            <div className={styles.qr_code_generation_error_content_logo}>
              <LogoTextIcon />
            </div>
            <div className={styles.qr_code_generation_error_content_info}>
              <ErrorIcon />
              <div className={styles.success_title}>
                {t('Generating_qr_code_error')}
              </div>
            </div>
          </div>
          <div className={styles.btn_container} >
            <div
              className={classNames({
                [`${styles.retry_action_button}`]: true,
              })}
              onClick={() => retryScanCode()}
            >
              {t("Retry")}
            </div>
            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
              })}
              // onClick={() => resetQrScanning(true, selectedQr, true)}
              onClick={() => setShowQrCodeGenerationError(false)}
            >
              {t("Cancel")}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderMinMaxAmountRange = (obj) => {
    return (
      <div className={styles.min_max_amount}>
        {amountFormatter(obj?.minAmount)} <span className={styles.uppercase}>{ccy}</span>
        <span className={styles.divider}> - </span>
        {amountFormatter(obj?.maxAmount)} <span className={styles.uppercase}>{ccy}</span>
      </div>
    )
  }

  const renderScanToPay = () => {
    const isMaxMinAmounts = activeQrCodeInfo.extension?.minAmount && activeQrCodeInfo.extension?.maxAmount
    return (
      <div className={styles.scan_to_pay_container}>
        <div className={styles.scan_navigation}>
          <BackButtonIcon onClick={() => setShowScanToPay(false)} className={styles.back_button} />
          <ShareIcon onClick={() => setShowShareModal(true)} />
        </div>
        <div className={styles.scan_to_pay_logo}>
          <LogoTextIcon />
          <div className={styles.bank_info}>
            <img src={`data:image/png;base64,${sellerInfo.bankInfo?.image}`} alt={sellerInfo.bankInfo?.bankName} />
          </div>
        </div>
        <div className={styles.scan_to_pay_amount}>
          {(activeQrCodeInfo.extension?.amount !== "" || isMaxMinAmounts) &&
            <div className={styles.scan_to_pay_amount_title}>
              {t('Amount_payable')}
            </div>}
          {updatingAmountAndQrInfo ? <CircularProgress size={16} /> : <div className={styles.scan_to_pay_amount_number}>
            {
              selectedQr === QR.STATIC || selectedQr === QR.DYNAMIC ?
                activeQrCodeInfo.extension?.amount ?
                  <>
                    {amountFormatter(activeQrCodeInfo.extension?.amount)} <span className={styles.uppercase}>{ccy}</span>
                  </> :
                  selectedQrAmount !== QR.FREE && selectedQr !== QR.HYBRID ?
                    renderMinMaxAmountRange(activeQrCodeInfo.extension) :
                    <div className={styles.min_max_amount}>
                      {t("No_specified_amount")}
                    </div>
                : selectedQr === QR.HYBRID && selectedQrAmount === QR.FIXED ? activeQrCodeInfo.extension?.amount ?
                  <>
                    {amountFormatter(activeQrCodeInfo.extension?.amount)} <span className={styles.uppercase}>{ccy}</span>
                  </> :
                  (modalValues?.minAmount && modalValues?.maxAmount) ?
                    renderMinMaxAmountRange(modalValues) :
                    <div className={styles.no_available_for_payments}>
                      {t("No_available_for_payments")}
                    </div> :
                  isMaxMinAmounts ?
                    renderMinMaxAmountRange(activeQrCodeInfo.extension) : modalValues?.amount ?
                      <>
                        {amountFormatter(activeQrCodeInfo.extension?.amount)} <span className={styles.uppercase}>{ccy}</span>
                      </> :
                      <div className={styles.no_available_for_payments}>
                        {t("No_available_for_payments")}
                      </div>
            }

          </div>}
        </div>
        <div className={styles.scan_to_pay_content}>
          <div className={styles.scan_to_pay_content_title}>
            {t('Scan_to_pay')}
          </div>
          {activeQrCodeInfo?.qrImage && <QrCodeIcon qrCode={activeQrCodeInfo?.qrImage} />}
        </div>
        <div className={styles.scan_to_pay_details}>
          <div className={styles.scan_to_pay_details_date}>
            <div>{moment(qrStoreDate).format("HH:mm:ss, DD.MM.YYYY")}</div>
          </div>
          <div className={styles.scan_to_pay_details_address}>
            <div>{sellerInfo?.merchantInfo.merchantName}</div>
            <div>{sellerInfo?.placeInfo.address}</div>
            <div>{sellerInfo?.placeInfo.name}</div>
          </div>

        </div>
        {<div className={styles.qr_action_btns_container}>
          {selectedQrAmount !== QR.FREE && selectedQr !== QR.DYNAMIC && <div className={styles.btn_container} onClick={() => setIsEditAmountModal(true)}>
            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
                [`${styles.set_edit_amount_button}`]: true,
              })}
            >

              {selectedQrAmount === QR.FIXED && !activeQrCodeInfo?.extension ? t('Set_amount') :
                selectedQrAmount === QR.CONTROLLED && !activeQrCodeInfo?.extension ? t('Set_amount') :
                  t('Edit_amount')}
            </div>
          </div>}

          <div className={styles.btn_container} >
            <div
              className={classNames({
                [`${styles.cancel_action_button}`]: true,
              })}
              onClick={() => selectedQr === QR.HYBRID ? setShowDeactivatedModalForHybrid(true) : setShowDeactivatedModal(true)}
            >
              {t('Deactivate')}
            </div>
          </div>
        </div>}
      </div>
    )
  }

  const retryScanCode = () => {
    setRetryAction(true)
    setShowQrCodeGenerationError(false)
    setShowSuccessView(false)
    setShowLoader(true)
    setShowScanToPay(false)
  }



  const renderQRCodeGenerationResponse = () => {
    return (
      <div className={styles.qr_code_generation}>
        <div className={styles.qr_code_container}>
          <div className={styles.qr_loader}>
            <LogoIcon className={styles.qr_logo} />
            <CircularProgress size={90} />
            {loaderText}
          </div>
        </div>
      </div>
    )
  }

  const renderPlaceholder = () => {
    return (
      <div className={styles.amount_placeholder}>
        0.00
      </div>
    )
  }

  const renderEnterAmountLabel = () => {
    let label = ""
    const switchLabel = `${selectedQr}-${selectedQrAmount}`

    switch (switchLabel) {
      case QR.STATIC_CONTROLLED:
        label = t("Enter_amount_range")
        break;
      case QR.STATIC_FIXED:
        label = t("Enter_amount")
        break;
      case QR.STATIC_FREE:
        label = t("Not_specified_amount")
        break;
      case QR.HYBRID_FIXED:
        label = t("Fixed_amount")
        break;
      case QR.HYBRID_CONTROLLED:
        label = t("Controlled_amount")
        break;
      default:
        label = t("Enter_amount")
        break;
    }

    return label
  }

  const setAmountField = (state, value, resetValue = false) => {
    setAmountError(false);
    let newAmount = ""
    if (!resetValue) {
      const newAm = activeQrCodeInfo.extension[state]

      if (value === "backspace") {
        newAmount = newAm.substring(0, newAm.length - 1);
      } else if (value === "." && newAm) {
        const isInAmountDott = containsAny(newAm, value)
        if (!isInAmountDott) {
          newAmount = `${newAm}${value}`
        } else {
          newAmount = newAm
        }

      } else if (value !== ".") {
        newAmount = `${newAm}${value}`;
        const countCharsAfterDot = countCharactersAfterDot(newAmount);
        const countCharsBeforeDot = countCharactersBeforeDot(newAmount);

        if (countCharsAfterDot <= 2 && countCharsBeforeDot <= 7) {
          newAmount = `${newAm}${value}`
        } else {
          newAmount = `${newAm}`
        }
      }
    }



    setActiveQrCodeInfo({
      ...activeQrCodeInfo,
      extension: {
        ...activeQrCodeInfo.extension,
        [state]: newAmount
      }
    })
  }

  const isNoStaticNoControlled = !(selectedQr === QR.STATIC && selectedQrAmount === QR.CONTROLLED)
  const disabledBtn = selectedQrAmount === QR.CONTROLLED ? !activeQrCodeInfo.extension?.maxAmount || !activeQrCodeInfo.extension?.minAmount : !activeQrCodeInfo.extension?.amount
  const showKeyBoard = selectedQr === QR.DYNAMIC ||
    (selectedQr === QR.STATIC && selectedQrAmount === QR.FIXED) ||
    (selectedQr === QR.STATIC && selectedQrAmount === QR.CONTROLLED)

  const renderKeyboard = () => {
    return (
      <div className={styles.keyboard_and_buttons}>
        <div className={styles.custom_keyboard}>
          <div className={styles.button_lines}>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 1) : setAmountField(QR.AMOUNT, 1)}>1</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 2) : setAmountField(QR.AMOUNT, 2)}>2</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 3) : setAmountField(QR.AMOUNT, 3)}>3</div>
          </div>
          <div className={styles.button_lines}>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 4) : setAmountField(QR.AMOUNT, 4)}>4</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 5) : setAmountField(QR.AMOUNT, 5)}>5</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 6) : setAmountField(QR.AMOUNT, 6)}>6</div>
          </div>
          <div className={styles.button_lines}>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 7) : setAmountField(QR.AMOUNT, 7)}>7</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 8) : setAmountField(QR.AMOUNT, 8)}>8</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 9) : setAmountField(QR.AMOUNT, 9)}>9</div>
          </div>
          <div className={classNames({
            [`${styles.button_lines}`]: true,
          })} >
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, ".") : setAmountField(QR.AMOUNT, ".")}> . </div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, 0) : setAmountField(QR.AMOUNT, 0)}>0</div>
            <div onClick={() => !isNoStaticNoControlled ? setAmountField(activeAmountField, "backspace") : setAmountField(QR.AMOUNT, "backspace")} className={styles.no_border}>
              <BackspaceIcon />
            </div>

          </div>
        </div>

      </div>
    )
  }

  const renderAmountError = () => {
    return (
      <div className={styles.amount_error}>
        <ErrorFillIcon />
        {t("Amount_error")}
      </div>
    )
  }

  const generateQrCode = () => {
    Number(activeQrCodeInfo.extension?.minAmount) >= Number(activeQrCodeInfo.extension?.maxAmount) &&
      Number(activeQrCodeInfo.extension?.minAmount) !== 0 &&
      Number(activeQrCodeInfo.extension?.maxAmount) !== 0 ? setAmountError(true) : setRetryAction(true)
  }

  const checkActiveQrCode = () => {


    setIsFetchingQrInfo(true)


    axios
      .get(`/pos/api/v2/qr/${activeQrCodeInfo?.qrId}?isImageRequired=true`)
      .then(res => {
        const { state } = res.data
        // not_found, pending, paid, cancelled, expired, error

        // if (state === "paid") {

        // } else if (state === "error") {


        // } else if (state === "cancelled") {



        // } else if (state === "expired") {


        // } else if (state === "not_found") {


        // } else 

        if (state === "pending") {
          setActiveQrCodeInfo({
            ...res.data.qrInfo
          })
          setShowScanToPay(true)
        } else {
          enqueueSnackbar(t(`Qr_activate_status_${state}`), { variant: "error" })
          setRunFetchCheckActiveQr(true)
        }
      }).finally(() => setIsFetchingQrInfo(false))
  }

  const checkForNull = activeQrCodeInfo.extension?.amount?.length > 0 && activeQrCodeInfo.extension?.amount !== "0" && activeQrCodeInfo.extension?.amount !== "0."
  const checkForNullForMin = activeQrCodeInfo.extension?.minAmount?.length > 0 && activeQrCodeInfo.extension?.minAmount !== "0" && activeQrCodeInfo.extension?.minAmount !== "0."
  const checkForNullForMax = activeQrCodeInfo.extension?.maxAmount?.length > 0 && activeQrCodeInfo.extension?.maxAmount !== "0" && activeQrCodeInfo.extension?.maxAmount !== "0."

  const disabledBtns = (disabledBtn && showKeyBoard) || amountError || (selectedQr !== QR.HYBRID && selectedQrAmount === QR.CONTROLLED ? (!checkForNullForMin || !checkForNullForMax) : selectedQr !== QR.HYBRID && selectedQrAmount !== QR.FREE ? !checkForNull : false)

  const renderActionButton = () => {
    return (
      <div className={styles.btn_container} >
        {!activeQrCodeInfo?.qrId ?
          <div
            className={classNames({
              [`${styles.day_action_button}`]: true,
              [`${styles.generate_qr_code}`]: true,
              [`${styles.day_action_button_disabled}`]: disabledBtns,
            })}
            onClick={() => disabledBtns ? {} : generateQrCode()}
          >
            {t("Generate_qr_code")} <GenerateQrIcon />
          </div>
          :
          <>
            <div
              className={classNames({
                [`${styles.day_action_button}`]: true,
                [`${styles.already_qr}`]: true,
                [`${styles.generate_qr_code}`]: true,
              })}
              onClick={() => checkActiveQrCode()}
            >
              {t("Qr_already_isset")} <NextIcon />
            </div></>}
        {amountError && renderAmountError()}
      </div>
    )
  }

  const renderAmountContainer = () => {
    return (
      <div className={styles.amount_container}>
        <div
          className={classNames({
            [`${styles.amount_value}`]: true,
            [`${styles.already_have_qr}`]: activeQrCodeInfo?.qrId,
          })}
        >
          {activeQrCodeInfo?.extension?.amount !== "" ? amountFormatter(activeQrCodeInfo?.extension?.amount) : renderPlaceholder()}
        </div>
        {activeQrCodeInfo?.extension?.amount !== "" && !activeQrCodeInfo?.qrId && <CloseRemoveIcon onClick={() => setAmountField(QR.AMOUNT, 0, true)} id={"REMOVE_AMOUNT"} />}
      </div>
    )
  }

  const renderDoubleAmountFields = () => {
    return (
      <div className={styles.two_amount_fields}>
        <div
          className={classNames({
            [`${styles.amount_container}`]: true,
            [`${styles.active_field}`]: activeAmountField === QR.MIN_AMOUNT,
          })}
          onClick={() => setActiveAmountField(QR.MIN_AMOUNT)}
        >
          <div
            className={classNames({
              [`${styles.amount_value}`]: true,
              [`${styles.already_have_qr}`]: activeQrCodeInfo?.qrId,
            })}>
            {activeQrCodeInfo.extension.minAmount !== "" ? amountFormatter(activeQrCodeInfo.extension.minAmount) : renderPlaceholder()}
          </div>
          {activeQrCodeInfo.extension.minAmount !== "" && !activeQrCodeInfo?.qrId && <CloseRemoveIcon onClick={() => setAmountField(QR.MIN_AMOUNT, 0, true)} id={"REMOVE_MIN_AMOUNT"} />}
        </div>
        <span>-</span>
        <div
          className={classNames({
            [`${styles.amount_container}`]: true,
            [`${styles.active_field}`]: activeAmountField === QR.MAX_AMOUNT,
          })}
          onClick={() => setActiveAmountField(QR.MAX_AMOUNT)}
        >
          <div
            className={classNames({
              [`${styles.amount_value}`]: true,
              [`${styles.already_have_qr}`]: activeQrCodeInfo?.qrId,
            })}>
            {activeQrCodeInfo.extension.maxAmount !== "" ? amountFormatter(activeQrCodeInfo.extension.maxAmount) : renderPlaceholder()}
          </div>
          {activeQrCodeInfo.extension.maxAmount !== "" && !activeQrCodeInfo?.qrId && <CloseRemoveIcon onClick={() => setAmountField(QR.MAX_AMOUNT, 0, true)} id={"REMOVE_MAX_AMOUNT"} />}
        </div>
      </div>
    )
  }

  const renderTerminalPageTitle = () => {
    const myLabel = `${selectedQr}-${selectedQrAmount}`
    const hideField = (activeQrCodeInfo?.qrId && selectedQr === QR.DYNAMIC) || (activeQrCodeInfo?.qrId && myLabel !== QR.STATIC_FREE && selectedQr === QR.STATIC)
    return (
      <>
        <span
          className={classNames({
            [`${styles.small_heading}`]: true,
            [`${styles.margin_top}`]: true,
          })}
        >
          {t(`${capitalizeFirstLetter(selectedQr)}_qr`)}
        </span>

        <span
          className={classNames({
            [`${styles.hide_field}`]: hideField,
            [`${styles.no_margin_top}`]: true,
          })}>

          {renderEnterAmountLabel()}
        </span>
      </>
    )
  }
  const renderTerminalOpenDay = () => {
    return (
      <div className={styles.terminal_enter_amount_container}>
        <div className={styles.terminal_enter_amount}>
          <div className={styles.enter_amount}>
            {renderTerminalPageTitle()}
            {showKeyBoard && isNoStaticNoControlled ?
              renderAmountContainer() :
              showKeyBoard && renderDoubleAmountFields()}
          </div>
          {!activeQrCodeInfo?.qrId && showKeyBoard && activeQrCodeInfo && renderKeyboard()}
          {renderActionButton()}
        </div>
      </div>
    )
  }

  const renderTerminalCloseDay = () => {
    return (

      <div className={styles.terminals_day_is_closed_container}>

        <div className={styles.terminals_day_is_closed}>
          <DayIsClosedIcon />
          <div className={styles.terminals_day_is_closed_title}>
            {t("Day_is_closed")}
          </div>
          <div className={styles.terminals_day_is_closed_description}>
            {t("Get_day_started")}
          </div>
          <div className={styles.btn_container} onClick={() => dispatch(setShowModalOpenCloseDay(true))}>
            <div
              className={classNames({
                [`${styles.day_action_button}`]: true,
                [`${styles.is_open}`]: isDayOpen,
              })}
            >
              {isDayOpen ? t("Close_day") : t("Open_day")}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const onDeactivateAction = () => {

    setIsDeactivating(true)
    const notif = "Qr_deactivated"
    const url = `${activeQrCodeInfo.qrId}`


    axios
      .delete(`/pos/api/v2/qr/${url}`)
      .then((res) => {
        setRunFetchCheckActiveQr(true)
        resetModalValues()
        const { data, status = 0 } = res
        if (data?.result === "success") {

          setIsDeactivating(false)
          // setShowScanToPay(alertScanTopay)
          enqueueSnackbar(t(notif))
          setShowDeactivatedModal(false)
          rPayment && removeRequestPayment()
          selectedQr !== QR.HYBRID ? setShowScanToPay(false) : setShowScanToPay(true)
          setModalValues({ amount: null, minAmount: null, maxAmount: null })
          setIsFetchingQrInfo(false)

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        } else {
          enqueueSnackbar(t("Amount_deactivated_qr"), { variant: "error" });
        }

      }).finally(() => {
        setIsDeactivating(false);
        setShowDeactivatedModal(false);
        resetModalValues()
      })
  }

  const resetModalValues = () => {

    setActiveQrCodeInfo({
      ...activeQrCodeInfo,
      extension: {
        ...activeQrCodeInfo.extension,
        amount: "",
        minAmount: "",
        maxAmount: ""
      }
    })

    setModalValues({ amount: null, minAmount: null, maxAmount: null })

    // setQrInfo({
    //   ...qrInfo,
    //   extension: {
    //     amount: "",
    //     minAmount: "",
    //     maxAmount: ""
    //   }
    // })
  }

  const onDeactivateActionForHybrid = () => {
    setIsDeactivating(true)
    let url = "";
    let notif = "";
    let notifError = "";
    let alertScanTopay = true
    if (deactivateAction === "deactivate_amount") {
      notif = "Amount_deactivated"
      notifError = "External_error_amount"
      url = `extension/${activeQrCodeInfo?.extension.extensionId}`
    } else {
      alertScanTopay = false
      notif = "Qr_deactivated"
      notifError = "Amount_deactivated_qr"
      url = `${activeQrCodeInfo.qrId}`
    }

    axios
      .delete(`/pos/api/v2/qr/${url}`)
      .then((res: any) => {
        const { status } = res

        if ((status === 200 || status === 201 || status === 204) && res?.data.result === "success") {
          setIsDeactivating(false)
          setRunFetchCheckActiveQr(true)
          setShowScanToPay(alertScanTopay)
          enqueueSnackbar(t(notif))
          setDeactivateAction("")

          setShowShareModal(false)
          resetModalValues()
          rPayment && removeRequestPayment()
        } else if (res?.data.result === "external_error") {
          enqueueSnackbar(t(notifError), { variant: "error" })
        }

      }).finally(() => {
        setShowDeactivatedModalForHybrid(false)
        setIsDeactivating(false)
      })
  }

  const onIsEditAmountModal = (qrData) => {
    setIsDisabledEditAmountSaveButton(true)

    const params = {
      amount: Number(modalValues.amount) || rPayment?.amount || 0,
      minAmount: Number(modalValues.minAmount) || 0,
      maxAmount: Number(modalValues.maxAmount) || 0
    }

    axios
      .post(`/pos/api/v2/qr/${qrData?.qrId}/extension`, { ...params })
      .then(res => {
        const { result } = res.data
        console.log("999999")

        if (result === "success") {

          //rPayment?.amount && removeRequestPayment()
          rPayment?.amount && setRequestPaymentAlredySet(true)
          setActiveQrCodeInfo({
            ...qrData,
            extension: {
              ...res.data.extensionInfo
            }
          })
          // setQrInfo({
          //   ...qrInfo,
          //   extension: {
          //     ...modalValues
          //   }
          // })


        } else if (result === "seller_is_inactive") {
          setShowLoader(false)
          removeLocalStorAndRefresh("seller_is_inactive", true)
        } else if (result === "terminal_is_inactive") {
          setShowLoader(false)
          removeTerminalId()
          removeLocalStorAndRefresh("terminal_is_inactive", false, false)
        } else if (result === "external_error") {
          setShowQrCodeGenerationError(false)
          enqueueSnackbar(t(`Edit_amount_summ_error`), { variant: "error" })
          setShowLoader(false)
        }
      })
      .catch(() => {
        setShowLoader(false);
        setIsEditAmountModal(false)
        setIsDisabledEditAmountSaveButton(false)
      })
      .finally(() => {
        setShowLoader(false);
        setIsEditAmountModal(false)
        setIsDisabledEditAmountSaveButton(false)
      })

  }

  const deactivatedModalForHybrid = () => {
    return (
      <Dialog
        open={showDeactivatedModalForHybrid}
        TransitionComponent={Transition}
        keepMounted
        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
          [`${styles.edit_modal_values}`]: true,
        })}
        style={{ zIndex: 99999 }}
        onClose={() => setShowDeactivatedModalForHybrid(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}> {t("Deactivate")}</span>
        </DialogTitle>
        <DialogContent style={{ overflow: "visible", padding: 0 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span className={styles.modal_description}>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <div className={styles.share_line}>
                    <FormControlLabel
                      value="deactivate_amount"
                      control={<Radio checked={deactivateAction === "deactivate_amount"} />}
                      label={t("Deactivate_amount")}
                      onClick={() => !activeQrCodeInfo?.extension ? {} : setDeactivateAction("deactivate_amount")}
                      disabled={!activeQrCodeInfo?.extension}
                    />
                  </div>
                  <div className={styles.share_line}>
                    <FormControlLabel
                      value="deactivate_qr"
                      control={<Radio checked={deactivateAction === "deactivate_qr"} />}
                      label={t("Deactivate_qr")}
                      onClick={() => setDeactivateAction("deactivate_qr")}
                    />
                  </div>

                </RadioGroup>
              </FormControl>


            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.edit_modal_action_button}>

          <Button onClick={() => { setShowDeactivatedModalForHybrid(false); setDeactivateAction("") }} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Cancel")}</span>
          </Button>
          <Button
            onClick={() => !deactivateAction || isDeactivating ? {} : onDeactivateActionForHybrid()}
            variant="text"
            className={classNames({
              [`${styles.confirm_btn}`]: true,
              [`${styles.day_action_button_disabled}`]: !deactivateAction || isDeactivating,
            })}
          >
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Apply")}
              {isDeactivating && <CircularProgress size={22} />}</span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }


  const deactivatedModal = () => {
    return (
      <Dialog
        open={showDeactivatedModal}
        TransitionComponent={Transition}
        keepMounted
        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
        })}
        style={{ zIndex: 99999 }}
        onClose={() => setShowDeactivatedModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}> {t("Confirm_deactivating")}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span className={styles.modal_description}>
              {t("Confirm_deactivating_description")}

            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => setShowDeactivatedModal(false)}
            variant="text"
            disabled={isDeactivating}>
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
              [`${styles.day_action_button_disabled}`]: isDeactivating,
            })}
            >{t("Cancel")}</span>
          </Button>
          <Button
            onClick={() => onDeactivateAction()}
            variant="text"
            disabled={isDeactivating}
          >
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
              [`${styles.confirm_btn}`]: true,
              [`${styles.day_action_button_disabled}`]: isDeactivating,
            })}
            >{t("Confirm")}
              {isDeactivating && <CircularProgress size={22} />}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const checkEditAmount = () => {
    selectedQrAmount === QR.FIXED ? onIsEditAmountModal(activeQrCodeInfo) : (Number(modalValues?.minAmount) >= Number(modalValues?.maxAmount) &&
      Number(modalValues?.minAmount) !== 0 &&
      Number(modalValues?.maxAmount) !== 0) ||
      modalValues?.minAmount === "" ||
      modalValues?.maxAmount === "" ?
      setAmountError(true) :
      onIsEditAmountModal(activeQrCodeInfo);
  }

  const changeModalAmount = (state, value) => {
    const regex = /^(0|[1-9]\d*)(\.\d+)?(e-?(0|[1-9]\d*))?$/i
    if ((regex.test(value) || value === "") && value.length <= 12) {
      setModalValues({ ...modalValues, [state]: value });
    }


    setAmountError(false);
  }

  const closeEditAmountModal = () => {
    setIsEditAmountModal(false);
    setAmountError(false);
    // resetModalValues();
  }

  const editAmountModal = () => {
    const noChanges = Number(modalValuesOld.amount) === Number(modalValues.amount) && Number(modalValuesOld.minAmount) === Number(modalValues.minAmount) && Number(modalValuesOld.maxAmount) === Number(modalValues.maxAmount)
    const emptyField = selectedQrAmount === QR.FIXED ? modalValues.amount && (Number(modalValues.amount) === 0 || modalValues.amount?.length === 0) : (Number(modalValues.minAmount) === 0 || Number(modalValues.maxAmount) === 0)

    return (
      <Dialog
        open={isEditAmountModal}
        TransitionComponent={Transition}
        keepMounted
        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
          [`${styles.edit_modal_values}`]: true,
        })}
        style={{ zIndex: 99999 }}
        onClose={() => { setIsEditAmountModal(false); setAmountError(false) }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}>
            {selectedQrAmount === QR.FIXED && !activeQrCodeInfo?.extension ? t('Confirm_add_amount') :
              selectedQrAmount === QR.CONTROLLED && !activeQrCodeInfo?.extension ? t('Confirm_add_amount') :
                t('Confirm_edit_amount')}

          </span>
        </DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span className={styles.modal_description}>

              {selectedQrAmount === QR.FIXED ? <TextField
                id="outlined-basic-login-idno"
                className={`${styles.sign_in_input} ${styles.mb_24}`}
                variant="outlined"
                value={modalValues.amount || ''}
                type="number"
                autoFocus={isEditAmountModal}
                onChange={(e) => changeModalAmount("amount", e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}

                InputProps={{ autoFocus: true }}
                label={t("Enter_amount")}
              /> :
                <>
                  <TextField
                    id="outlined-basic-login-idno"
                    className={`${styles.sign_in_input} ${styles.mb_24}`}
                    variant="outlined"
                    value={modalValues.minAmount || ''}
                    onChange={(e) => changeModalAmount("minAmount", e.target.value)}
                    fullWidth
                    autoFocus={isEditAmountModal}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label={t("From")}
                  />

                  <TextField
                    id="outlined-basic-login-idno"
                    className={`${styles.sign_in_input} ${styles.mb_24}`}
                    variant="outlined"
                    value={modalValues.maxAmount || ''}
                    onChange={(e) => changeModalAmount("maxAmount", e.target.value)}
                    fullWidth
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label={t("To")}
                  />
                </>}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.edit_modal_action_button}>

          <Button onClick={() => closeEditAmountModal()} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Cancel")}</span>
          </Button>
          <Button
            onClick={() => checkEditAmount()}
            variant="text"
            disabled={isDisabledEditAmountSaveButton || noChanges || amountError || emptyField}
            className={classNames({
              [`${styles.confirm_btn}`]: true,
              [`${styles.day_action_button_disabled}`]: isDisabledEditAmountSaveButton || noChanges || amountError || emptyField,
            })}
          >
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Confirm")}

              {isDisabledEditAmountSaveButton && <CircularProgress size={22} />}</span>
          </Button>
        </DialogActions>
        {amountError && renderAmountError()}
      </Dialog>
    )
  }

  const handleClipboardCopy = () => {
    navigator.clipboard.writeText(activeQrCodeInfo?.qrText);

  };

  const onCopyAction = (action, text) => {
    if (action === "copy") {
      enqueueSnackbar(t(text), { variant: 'success' })
      handleClipboardCopy()
    } else {
      const platformType = localStorage.getItem("x_platform_type");
      const isWeb = platformType === "web"
      const myDate = moment().format("DD-MM-YYYY--HH-mm");
      if (isWeb) {
        enqueueSnackbar(t(text), { variant: 'success' })
        downloadPdfFile(activeQrCodeInfo.qrImage, `mia-pos-qr-image-${myDate}`, "png");
      } else {
        enqueueSnackbar(t(text), { variant: 'success' })

        const params = {
          filename: `mia-pos-qr-image-${myDate}.png`,
          base64: activeQrCodeInfo.qrImage,
        }
        MiaPos.send(SAVE_FILE, { ...params }).then(res => {
          !res.result && enqueueSnackbar(t('File_not_saved'), { variant: 'error' })
        })

      }
    }

    setShowShareModal(false)
  }

  const shareModal = () => {
    return (
      <Dialog
        open={showShareModal}
        TransitionComponent={Transition}
        keepMounted
        className={classNames({
          [`${styles.modal_blur_bg}`]: true,
          [`${styles.edit_modal_values}`]: true,
        })}
        style={{ zIndex: 99999 }}
        onClose={() => setShowShareModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <span className={styles.modal_title}> {t("Share_qr_title")}</span>
        </DialogTitle>
        <DialogContent style={{ overflow: "visible", padding: 0 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <span className={styles.modal_description}>
              <div className={styles.share_line} onClick={() => onCopyAction("copy", "Link_copied")}>
                <CopyIcon /> {t("Copy_link")}
              </div>
              <div className={styles.share_line} onClick={() => onCopyAction("download", "Qr_download_started")}>
                <DownloadIcon /> {t("Download_qr")}
              </div>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.edit_modal_action_button}>

          <Button onClick={() => setShowShareModal(false)} variant="text">
            <span className={classNames({
              [`${styles.modal_btn}`]: true,
            })}
            >{t("Cancel")}</span>
          </Button>

        </DialogActions>
      </Dialog>
    )
  }

  return (
    <AppMainLayout
      activeNav={NAV.TERMINAL}
      backButtonUrl={"/app/transactions"}
      terminalId={sellerInfo?.terminalInfo?.terminalId}
      removeLocalStorAndRefresh={removeLocalStorAndRefresh}
    >
      {deactivatedModal()}
      {deactivatedModalForHybrid()}
      {isEditAmountModal && editAmountModal()}
      {shareModal()}

      {showLoader && renderQRCodeGenerationResponse()}
      {showScanToPay && renderScanToPay()}
      {showSuccessView && renderSuccessView()}
      {/* {renderSuccessView()} */}
      {showQrCodeGenerationError && renderQrCodeGenerationError()}
      <div className={styles.terminal_addres}>
        <div>
          {`${sellerInfo?.merchantInfo.merchantName}, `}
          {`${sellerInfo?.placeInfo.address}, `}
          {sellerInfo?.placeInfo.name}
        </div>
      </div>

      <div
        className={classNames({
          [`${styles.terminal_container}`]: true,
          [`${styles.is_fetching_qr_info}`]: isFetchingQrInfo,
        })}
      >
        {isFetchingQrInfo ? <CircularProgress size={50} /> : (isDayOpen || dayState === "opened") ? renderTerminalOpenDay() : renderTerminalCloseDay()}
      </div>

    </AppMainLayout>
  );
};
export default Terminal;
