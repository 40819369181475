import React from "react";

export const TransactionReturnedIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#EBF0F7" />
            <path d="M9.33329 6L12.3333 6C13.9901 6 15.3333 7.34315 15.3333 9C15.3333 10.6569 13.9901 12 12.3333 12L4.66663 12" stroke="#A1ABB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.66661 10C6.66661 10 4.66663 11.473 4.66663 12C4.66662 12.5271 6.66663 14 6.66663 14" stroke="#A1ABB2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
