import Store from "../store"
import axios from "axios";
import moment from "moment"
import {
    // getToken,
    // isTokenPresent,
    storeToken,
    storeRefreshToken,
    storeExpiredTime,
    removeExpiredTime,
    removeRefreshToken,
    // removeToken,
    storeTerminalError,
    removeTerminalId,
    removeDayState,
    removeSellerInfo,
    removeShiftId
} from "../services/Auth";

Store.subscribe(listener);

// function select(state) {
//     return state.TokenReducer;
// }

function listener() {

    // const testToken = isTokenPresent() ? getToken() : "";
    const XAppVresion = localStorage.getItem("x_pos_app_version");
    const XInstallationId = localStorage.getItem("x_installation_id");
    const XPlatformVersion = localStorage.getItem("x_platform_version");
    const XPlatformType = localStorage.getItem("x_platform_type");
    const XDevice = localStorage.getItem("x_device");

    //console.log("+++++++++++++++++++++++++++ ", testToken, "///", testToken?.length)
    //const token = select(Store.getState()) || null;
    //if (testToken?.length > 0) {
    axios.defaults.headers.common['X-DEVICE'] = XDevice;
    axios.defaults.headers.common['X-PLATFORM-TYPE'] = XPlatformType;
    axios.defaults.headers.common['X-PLATFORM-VERSION'] = XPlatformVersion;
    axios.defaults.headers.common['X-INSTALLATION-ID'] = XInstallationId;
    axios.defaults.headers.common['X-POS-APP-VERSION'] = XAppVresion;
    // }

}

axios.defaults.baseURL = process.env.PUBLIC_URL;

axios.interceptors.request.use(async (config) => {
    // const refreshToken = localStorage.getItem('_session_token');
    if (config.url === "/pos/api/v1/seller/refresh") {
        config.headers['Authorization'] = ``;
        config.headers['X-DEVICE'] = ``;
        config.headers['X-PLATFORM-TYPE'] = ``;
        config.headers['X-PLATFORM-VERSION'] = ``;
        config.headers['X-INSTALLATION-ID'] = ``;
        config.headers['X-POS-APP-VERSION'] = ``;
        config.headers['X-NATIVE-APP-VERSION'] = ``;
        return config
    }

    const rToken = localStorage.getItem('_refresh_token');
    const expTime = localStorage.getItem('_expired_time');
    const terminalActivationId = localStorage.getItem('_terminal_id');

    const now = moment()
    const secDiff = now.diff(expTime, "seconds");

    const diff = (secDiff * -1) < 20 ? true : false
    if (diff) {
        try {
            if (terminalActivationId) {
                const params = {
                    refreshToken: rToken,
                    terminalActivationId
                }
                const response = await axios.post('/pos/api/v1/seller/refresh', { ...params });
                const { data: { result } } = response

                if (result === "success") {
                    storeToken(response.data.authTokens.accessToken)
                    storeRefreshToken(response.data.authTokens.refreshToken)
                    const updatedDate = moment().add(response.data.authTokens.accessTokenExpiresIn, 'seconds');
                    storeExpiredTime(updatedDate)
                    if (!response.data.authTokens.accessToken) {
                        storeTerminalError(result)
                    }
                } else {
                    storeTerminalError(result)
                }
            }



        } catch (error) {
            //removeToken()
            removeRefreshToken()
            removeDayState()
            removeSellerInfo()
            removeExpiredTime()
            removeShiftId()
            //storeTerminalError(error)
            removeTerminalId()
            console.log("error++++++++++ ", error)
            //window.location.reload()
            return Promise.reject(error);
        }
    }
    const arrUrls = [
        "/pos/api/v1/seller/login",
        "/pos/api/v1/terminal-activation/init",
        "/pos/api/v1/terminal-activation/otp-resend",
        "/pos/api/v1/terminal-activation/confirm",
    ]

    // console.log("config.url", config.url)
    const sessionToken = localStorage.getItem('_session_token');
    if (arrUrls.findIndex(i => i === config.url) === -1) {
        config.headers.Authorization = `Bearer ${sessionToken}`;
    } else {
        config.headers.Authorization = ``;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => response,
    error => {
        // if (error.response.status === 401) {
        //     //TO DO: later need to logic with logout
        // }
        return error;
    });

export default axios;
