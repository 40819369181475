import React, { useEffect, useState } from 'react';
import axios from "../../../api/services/Api";
import { onlyNumbersRegex, capitalizeFirstLetter } from '../../../constants';
import { IStepOtp } from "./types"
import { useTranslation } from 'react-i18next';
import OtpInput from "react-otp-input";
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect } from "react-router-dom";
import { enqueueSnackbar } from 'notistack';
import { ErrorFillIcon } from "../../../svg-icons/icon-error-fill"
import { useSelector } from "react-redux";
import { getTokenTerminalId } from "../../../api/selectors/authSelector"
import {
    storeTerminalId,
  } from "../../../api/services/Auth";

import './styles.scss';

const StepOtp = ({
    showLoginForm,
    waitTimeToResend,
    maskedPhoneNumber = "",
    terminalActivationId,
    hideOtpFormAndShowTerminal,
    setShowTerminalSuccessActivated
}: IStepOtp) => {

    const { t } = useTranslation();

    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState("")
    const [digitsError, setDigitsError] = useState(false)
    const [resendMessage, setResendMessage] = useState(false)
    const [showResendButton, setShowResendButton] = useState(false)
    const [leftAttempts, setLeftAttempts] = useState(null)
    const [newOtp, setNewOtp] = useState("")
    const [redirect, setRedirect] = useState(false);
    const [currentCount, setCount] = useState(waitTimeToResend);

    const tokenData = useSelector(getTokenTerminalId)
    
    useEffect(() => {
        if (!maskedPhoneNumber || !waitTimeToResend) {
            setRedirect(true)
        }
    }, [maskedPhoneNumber, waitTimeToResend])

    useEffect(() => {
        const timer = () => setCount(currentCount - 1);
        if (currentCount <= 0) {
            setShowResendButton(true);
            return;
        }
        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [currentCount]);

    useEffect(() => {

        const onResendMessage = () => {
            setIsFetching(true)
            setNewOtp("")
            setShowResendButton(false)
            setResendMessage(false)
            setError("")
            const params = {
                terminalActivationId
            }
            axios.post(`/pos/api/v1/terminal-activation/otp-resend`, { ...params })
                .then(res => {

                    if (res.data.result === "success") {
                            // enqueueSnackbar("OK", { variant: 'success' })
                            setLeftAttempts(res.data.leftAttempts)
                            setCount(res.data.waitTimeToResend)
                            setIsFetching(false)

                    } else {
                        setNewOtp("")
                        setLeftAttempts(0)
                        setError(capitalizeFirstLetter(res.data.result))
                        enqueueSnackbar(t(capitalizeFirstLetter(res.data.result)), { variant: 'error' })
                        setIsFetching(false)
                        res.data.result === "otp_limit_exceeded" && hideOtpFormAndShowTerminal()
                    }

                })

        }

        resendMessage && onResendMessage()
    }, [resendMessage, terminalActivationId, t, hideOtpFormAndShowTerminal])

    useEffect(() => {
        document.getElementById(`otp-input-0`)?.focus();
    }, [])


    const checkOtp = (editedOtp) => {

        setIsFetching(true)
        setError("")

        const params = {
            terminalActivationId,
            otpCode: editedOtp

        }
        axios.post(`/pos/api/v1/terminal-activation/confirm`, { ...params })
            .then(res => {
                if (res.data.result === "success") {
                    storeTerminalId(tokenData);
                    showLoginForm(true)
                    setIsFetching(false)
                    setShowTerminalSuccessActivated()
                    //enqueueSnackbar(t("Success"), { variant: 'success' })
                } else if (res.data.result === "wrong_otp") {
                    setNewOtp("")
                    setError(t(capitalizeFirstLetter(res.data.result)))
                    // enqueueSnackbar(t(capitalizeFirstLetter(res.data.result)), { variant: 'error' })
                    setLeftAttempts(res.data.leftAttempts)
                    setIsFetching(false)
                } else {
                    enqueueSnackbar(t(capitalizeFirstLetter(res.data.result)), { variant: 'error' })
                    setNewOtp("")
                    setError(t(capitalizeFirstLetter(res.data.result)))
                    setIsFetching(false)
                    hideOtpFormAndShowTerminal()
                }
            })
            .catch(err => {
                setIsFetching(false)
                setError(t("Error_server"))
                enqueueSnackbar(t("Error_server"), { variant: 'error' })
            });
    }

    const otpCheckInProcess = () => {
        return (
            <div className="otp-process">
                <CircularProgress size={50} className="circular-yellow" />
            </div>
        )
    }

    const otpCheckFailed = () => {
        return (
            <div className={"error-content"}>
                <ErrorFillIcon />
                <label>{t(error)}</label>
                {leftAttempts && <div style={{ marginLeft: 10 }}>{t('Attempts_left')}: {leftAttempts}</div>}
            </div>
        )
    }

    const digitsOtpError = () => {
        return (
            <div className="otp-failed">
                <label>{t('Enter_only_numbers')}</label>
            </div>
        )
    }

    const setOtp = (value) => {

        setError("");
        setDigitsError(false);

        const isValid = onlyNumbersRegex(value);
        if (isValid || value === "") {
            setNewOtp(value);

            if (value.length === 6) {
                checkOtp(value);
            }
        } else {
            setDigitsError(true);
        }
    }


    const renderOtpInputs = () => {
        return (
            <OtpInput
                value={newOtp}
                onChange={(e) => setOtp(e)}
                numInputs={6}
                shouldAutoFocus
                inputStyle={`text-input-otp ${error && "error-styles"}`}
                renderInput={(props, i) => {
                    return (
                        <input
                            {...props}
                            type={"number"}
                            inputMode={"numeric"}
                        // disabled={ newOtp.length < i}
                        />
                    );
                }}
            />
        )
    }

    const renderFormSection = () => {
        return (

            <div className="user-data-container">
                <div className="step-one-substeps-container">

                    {isFetching ? otpCheckInProcess() :
                        <div className="otp-container">
                            <div className="subtitle">
                                {t('Enter_otp_code_send_to')} <span>{maskedPhoneNumber}</span>
                            </div>

                            <div className="justify-align-center">
                                {renderOtpInputs()}
                            </div>
                            {error && otpCheckFailed()}
                            <div className="resend-container">
                                <div>{t('No_code')}</div>
                                {showResendButton ?
                                    <span onClick={() => setResendMessage(true)}>
                                        {t('Send_sms_one_more_time')}
                                    </span> :
                                    <b>{t('Can_send_after')} {currentCount} {t('Sec')}</b>
                                }
                            </div>
                        </div>
                    }

                   
                    {digitsError && digitsOtpError()}
                </div>
            </div>

        )
    }

    return (
        <div className="step-container">
            {redirect && <Redirect to="/app/" />}
            {renderFormSection()}
        </div>
    );
};

export default StepOtp;